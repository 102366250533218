import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Product2 } from '../interfaces/product';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any = {};
  selectedProduct: Product2 = undefined;
  private productFound = new EventEmitter<void>();
  startTime: number;
  playTime: number;

  constructor(private http: HttpClient) {}

  getProductFound$(): Observable<void> {
    return this.productFound.asObservable();
  }

  foundProduct(time: number) {
    this.playTime = Math.round((time - this.startTime) / 1000);
    if (!this.user || !this.user.email) {
      return;
    }

    const body: any = {
      input_1: this.user.email,
      input_2: this.selectedProduct.name,
      input_3: Math.min(this.playTime, 60).toString(),
      input_4: 'Individual',
      input_5: 'PersonAccount',
      input_6: 'Lead',
      input_7: this.user.firstName,
      input_8: this.user.lastName,
    };

    if (this.user.middleName) {
      body.input_10 = this.user.middleName;
    }

    return this.http
      .post(
        'https://bartimeusfonds.nl/wp-json/gf/v2/forms/155/submissions',
        body
      )
      .pipe(
        catchError(() => {
          return of({});
        }),
        tap(() => {
          this.selectedProduct = undefined;
          this.productFound.next();
        })
      );
  }

  isInTime(): boolean {
    return this.playTime && this.playTime <= 60;
  }

  setUser(user: any) {
    const body: any = {
      input_1: user.email,
      input_2: user.firstName,
      input_4: user.lastName,
      input_32: user.firstName.charAt(0),
      input_11: user.agreement,
      input_26: 'PersonAccount',
      input_28: 'Lead',
      input_30: 'Individual',
      // input_29: '', // TODO: Nog in te vullen
    };

    if (user.middleName) {
      body.input_34 = user.middleName;
    }

    if (user.allowContact) {
      const date = new Date();

      body.input_20 =
        'Ja, ik wil op de hoogte worden gebracht van ontwikkelingen';
      body.input_21 = 'https://www.bestellenin60tellen.nl/';
      body.input_22 = 'Website';
      body.input_24 = 'true';
      body.input_25 = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    }

    if (user.zipCode && user.houseNumber) {
      body.input_5 = user.zipCode;
      body.input_8 = user.streetName;
      body.input_9 = user.city;
      body.input_15 = user.houseNumber;
    }

    if (user.houseNumberAddition) {
      body.input_7 = user.houseNumberAddition;
    }

    return this.http
      .post(
        'https://bartimeusfonds.nl/wp-json/gf/v2/forms/154/submissions',
        body
      )
      .pipe(
        catchError(() => {
          return of({});
        }),
        tap(() => {
          this.user = user;
        })
      );
  }
}

import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './shared/services/cart.service';
import { CurrencyService } from './shared/services/currency.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private toastr: ToastrService,
    private cart: CartService,
    private zone: NgZone,
    private scroller: ViewportScroller,
    private currency: CurrencyService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    console.info('Version: ', '1.5.6');
    // properties of the CurrencyFormatOptions interface fully complies
    // with the arguments of the built-in pipe "currency"
    // https://angular.io/api/common/CurrencyPipe
    this.currency.options = {
      code: 'EUR',
      // display: 'symbol',
      // digitsInfo: '1.2-2',
      // locale: 'en-US'
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sendTracking(event);
        this.scroller.scrollToPosition([0, 0]);
      }
    });
    this.cart.onAdding$.subscribe((product) => {
      this.toastr.success(`Product "${product.name}" toegevoegd aan mandje!`);
    });
  }

  sendTracking(event: NavigationEnd): void {
    // @ts-ignore
    if (!!window.mt) {
      if (!this.userService.user || !this.userService.user.email) {
        (window as any).mt('send', 'pageview');
      } else {
        (window as any).mt('send', 'pageview', {
          email: this.userService.user.email,
        });
      }
    }

    // @ts-ignore
    if (!!window.ga) {
      (window as any).ga('gtm2.set', 'page', event.urlAfterRedirects);
      (window as any).ga('gtm2.send', 'pageview');
    }

    // @ts-ignore
    if (!!window.dataLayer) {
      (window as any).dataLayer.push({ event: 'pageview' });
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          const preloader = document.querySelector('.site-preloader');

          preloader.addEventListener(
            'transitionend',
            (event: TransitionEvent) => {
              if (event.propertyName === 'opacity') {
                preloader.remove();
              }
            }
          );
          preloader.classList.add('site-preloader__fade');
        }, 300);
      });
    }
  }
}
